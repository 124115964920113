var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "en-att-status-contain" }, [
    _c(
      "div",
      {
        staticClass: "en-att-status-icon",
        class: {
          zc: _vm.status === 1 || _vm.status === 7,
          cd: _vm.status === 2,
          zt: _vm.status === 3,
          tg: _vm.status === 4,
          yc: _vm.status === 11,
          kg: _vm.status === 12,
        },
      },
      [
        _c("en-icon", {
          attrs: { name: _vm.statusIconName, size: "16", color: "#FFFFFF" },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "status-name" }, [
      _vm._v(" " + _vm._s(_vm.statusName) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }