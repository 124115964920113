import { request } from "en-js";
import dayjs from "dayjs";
import { signInService } from "@/api";
import { enEqual } from "@/tools/compare";

// 事件列表
const actionMethods = {
  // 获取考勤记录
  requestRecordDataList(params) {
    if (params === null) {
      params = {
        startDate: new Date(),
        endDate: new Date()
      };
    }
    if (params.startDate === null) {
      params.startDate = new Date();
    }
    if (params.endDate === null) {
      params.endDate = new Date();
    }
    Object.assign(this.dateRange, params);
    this.requestRecordsData();
  }
};

// 请求方法
const requestMethods = {
  async requestAttConfig() {
    const rsp = await signInService.queryUserAttConfig();
    this.attModelData = rsp;
  },
  async requestRecordsData() {
    const params = {
      startDate: dayjs(this.dateRange.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(this.dateRange.endDate).format("YYYY-MM-DD")
    };
    const rsp = await signInService.queryInfoRecords(params);
    this.infoRecordList = rsp;
  },
  @request(true)
  async requestSignIn(params) {
    const rsp = await signInService.signIn(params);
    this.signInData = rsp;
  },
  async requestUpdateSignIn(params) {
    const result = await signInService.updateSignIn(params);
    return result;
  },
  async requestUserAttData(params) {
    const rsp = await signInService.queryUserAttData(params);
    this.attPersonData = rsp;
  },
  async requestUserAttDetail(params) {
    const rsp = await signInService.queryUserAttDetail(params);
    this.attPersonDetailData = rsp;
  },
  async requestObjectTreeData(params) {
    const rsp = await signInService.queryObjectTreeExtAccess(params);
    return rsp;
  }
};

const uiMethods = {
  // 更新首页打卡时间
  updateHomeRecordTime() {
    if (this.infoRecordList?.length > 0) {
      const attR = this.infoRecordList[0];
      const recordTimeList = [];
      recordTimeList.push(...attR.attRecords);
      for (let i = 0; i < attR.infoRecords.length; i++) {
        const infoR = attR.infoRecords[i];
        recordTimeList.push(...infoR.attRecords);
      }
      const recordTimes = [];
      for (let i = 0; i < recordTimeList.length; i++) {
        const attRecord = recordTimeList[i];
        if (attRecord.recordTime) {
          if (attRecord.recordTime.length > 0) {
            recordTimes.push(attRecord.recordTime);
          }
        }
      }
      let tmpRecordTime = "";
      if (recordTimes.length > 0) {
        recordTimes.reverse();
        const rTime = recordTimes[0];
        if (rTime.length > 0) {
          this.homeShowTime = dayjs(rTime).format("HH:mm");
          tmpRecordTime = rTime;
        }
      }

      this.homeIsAbNormal = false;
      for (let i = 0; i < recordTimeList.length; i++) {
        const attRecord = recordTimeList[i];
        if (attRecord.recordTime) {
          if (attRecord.recordTime === tmpRecordTime) {
            if (attRecord.status === 2
              || attRecord.status === 3
              || attRecord.status === 5
              || attRecord.status === 11
              || attRecord.status === 12
              || attRecord.status === 17
              || attRecord.status === 18) {
              this.homeIsAbNormal = true;
            }
          }
        }
      }
    }
  },
  // 查询区间是否为今天
  isTodayRange() {
    const today = dayjs(new Date()).format("YYYY-MM-DD");
    const start = dayjs(this.dateRange.startDate).format("YYYY-MM-DD");
    const end = dayjs(this.dateRange.endDate).format("YYYY-MM-DD");
    if (today === start && today === end) {
      return true;
    }
    return false;
  },
  // 获取某天的考勤策略
  getUserCurrentStrategyEntity(date, isLiWai) {
    const isLiWaiSign = isLiWai;
    const strategyEntity = {};
    const relationList = [];
    let isNeedAtt = true;// 是否需要考勤
    let isHasOutType = false;// 是否有外勤
    let isRemark = false;
    let isPicture = false;
    let isModify = false;
    let isMustCamera = false;

    if (date === null) {
      date = new Date();
    }

    for (let i = 0; i < this.attModelData.attModel.length; i++) {
      const attEntity = this.attModelData.attModel[i];
      if (!isHasOutType && Number(attEntity.type) === 4) {
        isHasOutType = true;
      }

      for (let j = 0; j < attEntity.strategy.length; j++) {
        const sEntity = attEntity.strategy[j];
        if (isNeedAtt && Number(sEntity.isAttendee) === 2) {
          isNeedAtt = false;
        }

        if (!isRemark) {
          if (isLiWaiSign) {
            if (Number(sEntity.exceptionStr.isRemark) === 0) {
              isRemark = true;
            }
          } else if (Number(sEntity.isRemark) === 0) {
            isRemark = true;
          }
        }

        if (!isPicture) {
          if (isLiWaiSign) {
            if (Number(sEntity.exceptionStr.isPicture) === 0) {
              isPicture = true;
            }
          } else if (Number(sEntity.isPicture) === 0) {
            isPicture = true;
          }
        }

        if (!isMustCamera) {
          if (isLiWaiSign) {
            if (Number(sEntity.exceptionStr.isPhoto) === 0) {
              isMustCamera = true;
            }
          } else if (Number(sEntity.isPhoto) === 0) {
            isMustCamera = true;
          }
        }

        if (!isModify) {
          if (isLiWaiSign) {
            if (Number(sEntity.exceptionStr.isModify) === 0) {
              isModify = true;
            }
          } else if (Number(sEntity.isModify) === 0) {
            isModify = true;
          }
        }

        if (isLiWaiSign) {
          relationList.push(...sEntity.exceptionStr.relations);
        } else {
          relationList.push(...sEntity.relations);
        }
      }
    }
    strategyEntity.isOfficeAtt = !isHasOutType;
    strategyEntity.isNeedAttendee = isNeedAtt;
    strategyEntity.isRemark = isRemark;
    strategyEntity.isPicture = isPicture;
    strategyEntity.isModify = isModify;
    strategyEntity.relations = [...relationList];
    strategyEntity.isMustCamera = isMustCamera;
    return strategyEntity;
  },

  // 是否异常(val为:考勤状态值)
  isAbnormal(val) {
    if (enEqual(val, 1) || enEqual(val, 7) || enEqual(val, 14) || enEqual(val, 15) || enEqual(val, 16)) {
      return false;
    }
    return true;
  },

  // 考勤状态简称(val为:考勤状态值)
  statusShortName(val) {
    if (Number(val) === 2) {
      return "迟";
    }
    if (Number(val) === 3) {
      return "退";
    }
    if (Number(val) === 11) {
      return "异";
    }
    if (Number(val) === 12) {
      return "旷";
    }
    if (Number(val) === 14) {
      return "假";
    }
    if (Number(val) === 15) {
      return "外";
    }
    if (Number(val) === 16) {
      return "出";
    }
    if (Number(val) === 18) {
      return "加";
    }
    return "";
  }
};

export default {
  data() {
    return {
      // 考勤打卡记录数据
      infoRecordList: [
        {
          attRecords: []
        }
      ],
      // 考勤配置返回数据
      attModelData: {
        formId: "",
        formType: "",
        attModel: []
      },
      // 考勤策略数据
      attStrategyData: {
        isNeedAttendee: true, // 是否需要考勤,否则直接打卡
        isOfficeAtt: false, // 是否是内勤
        isPicture: false, // 是否必须上传图片
        isRemark: false, // 是否必须填写备注
        isModify: false, // 提交后是否可以修改
        isMustCamera: false, // YES时,隐藏相册选择图片的功能
        relations: [{
          objectTypeName: "",
          objectTypeId: "", // 基础数据类型id
          isRequired: "", // 是否必填（0必填、1非必填）
          value: "", // 选中值
          valueText: "", // 选中值中文
          objectDataType: "", // "自定义 000、组织机构 001、人员 002、岗位 003、职级 004、科目 005、客商 006、合同 007、项目 008 、客商类别 009、关系类型 010 、支付方式 011'、城市类别 012"
          dataName: "",
          dataId: ""// 基础数据类型id
        }]
      },
      // 签到返回数据
      signInData: {},
      // 查询考勤记录日期区间
      dateRange: {},
      // 首页显示打卡时间
      homeShowTime: "",
      homeIsAbNormal: false,
      attPersonData: {},
      attPersonDetailData: {}
    };
  },
  watch: {
    infoRecordList() {
      if (this.isTodayRange()) {
        this.updateHomeRecordTime();
      }
    },
    attModelData() {
      // console.log(this.attModelData);
      const attStrategyData = this.getUserCurrentStrategyEntity();
      this.attStrategyData = attStrategyData;
      // console.log(attStrategyData);
    }
  },
  methods: {
    ...actionMethods,
    ...requestMethods,
    ...uiMethods
  }
};
