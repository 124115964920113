var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "my-dialog",
      attrs: {
        "append-to-body": true,
        "show-close": false,
        "close-on-click-modal": false,
        visible: _vm.dialogDetailVisible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogDetailVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "body-contain",
          style: _vm.isCanEdit ? "height:440px;" : "height: 394px;",
        },
        [
          _c("span", { staticClass: "title" }, [_vm._v("考勤说明")]),
          _c(
            "div",
            { on: { click: _vm.closeClickAction } },
            [
              _c("en-icon", {
                staticClass: "close-icon",
                style: { fill: "#A9B5C6" },
                attrs: { name: "guanbi-danchuang", size: "14px" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "content-body-contain" },
            [
              _c(
                "div",
                { staticClass: "att-detail-contain" },
                [
                  _c("en-icon", {
                    staticClass: "detail-icon",
                    attrs: { name: "zuiwandaodian-wodehangcheng", size: "14" },
                  }),
                  _c("div", { staticClass: "detail-label" }, [
                    _vm._v(" " + _vm._s(_vm.dataInfo.recordTime) + " "),
                  ]),
                  _c("en-icon", {
                    staticClass: "detail-icon",
                    attrs: { name: "chufachengshi-budan", size: "14" },
                  }),
                  _c("div", { staticClass: "detail-label" }, [
                    _vm._v(" " + _vm._s(_vm.dataInfo.ipAddress) + " "),
                  ]),
                  _c("attStatusIcon", {
                    staticClass: "status-icon",
                    attrs: { status: _vm.dataInfo.status },
                  }),
                ],
                1
              ),
              _c(
                "el-form",
                {
                  ref: "dataInfo",
                  attrs: {
                    mode: _vm.dataInfo,
                    "label-position": "left",
                    disabled: !_vm.isCanEdit,
                  },
                },
                [
                  _vm._l(_vm.dataInfo.projectList, function (dataItem) {
                    return _c(
                      "el-form-item",
                      {
                        key: dataItem.id,
                        attrs: { prop: dataItem.id, label: dataItem.name },
                      },
                      [
                        _c("en-select", {
                          attrs: {
                            mode: dataItem.hasChildren ? "tree" : "normal",
                            data: dataItem.optionList,
                            props: {
                              value: "id",
                              label: "name",
                              children: "nodes",
                            },
                            "data-mode": "data",
                            loading: _vm.loading,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.selectChange($event, dataItem)
                            },
                            "visible-change": function ($event) {
                              return _vm.remoteMethod($event, dataItem)
                            },
                          },
                          model: {
                            value: _vm.selectData[dataItem.objectTypeId],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.selectData,
                                dataItem.objectTypeId,
                                $$v
                              )
                            },
                            expression: "selectData[dataItem.objectTypeId]",
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "remark", label: "备注" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "500",
                          autosize: { minRows: 2, maxRows: 4 },
                          readonly: !_vm.isCanEdit,
                          placeholder: "请输入考勤备注内容",
                        },
                        model: {
                          value: _vm.dataInfo.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInfo, "remark", $$v)
                          },
                          expression: "dataInfo.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "att-image-title-contain" },
                [
                  _vm.dataInfo.picList.length
                    ? _c(
                        "div",
                        { staticClass: "form-att atta-file-wrap" },
                        _vm._l(_vm.dataInfo.picList, function (item, _index) {
                          return _c("att-image", {
                            key: item.code,
                            attrs: {
                              imgdata: item,
                              deleteabled: _vm.isCanEdit,
                            },
                            on: {
                              delImg: () => {
                                _vm.deleteRow(_index, _vm.dataInfo.picList)
                              },
                            },
                          })
                        }),
                        1
                      )
                    : _vm.signInfoData.attachment.length
                    ? _c(
                        "div",
                        { staticClass: "form-att atta-file-wrap" },
                        _vm._l(
                          _vm.signInfoData.attachment,
                          function (item, _index) {
                            return _c("att-image", {
                              key: item.code,
                              attrs: {
                                imgdata: item,
                                deleteabled: _vm.isCanEdit,
                              },
                              on: {
                                delImg: () => {
                                  _vm.deleteRow(
                                    _index,
                                    _vm.signInfoData.attachment
                                  )
                                },
                              },
                            })
                          }
                        ),
                        1
                      )
                    : _vm._e(),
                  _vm.isCanEdit && _vm.dataInfo.picList.length < 9
                    ? _c("en-upload", {
                        class: _vm.signStrategyData.isMustCamera
                          ? "must-camera"
                          : "no-must-camera",
                        attrs: {
                          title: _vm.signStrategyData.isMustCamera
                            ? "请使用手机端拍照上传图片"
                            : "添加图片",
                          "on-change": _vm.handleUploadChange,
                          limitNum: "9",
                          limitType: "3",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isCanEdit
            ? _c(
                "en-button",
                {
                  staticClass: "comfirm-button",
                  on: { click: _vm.confirmClick },
                },
                [_vm._v("确定")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }