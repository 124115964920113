<!--考勤打卡状态-->
<template>
  <div class="en-att-status-contain">
    <div
      class="en-att-status-icon"
      :class="{
      zc:status === 1 || status === 7,
      cd:status === 2,
      zt:status === 3,
      tg:status === 4,
      yc:status === 11,
      kg:status === 12,
    }"
    >
      <en-icon :name="statusIconName" size="16" color="#FFFFFF"></en-icon>
    </div>
    <div class="status-name">
      {{ statusName }}
    </div>
  </div>
</template>

<script>
  export default {
    name: "EnAttStatusIcon",
    props: {
      // 状态码
      status: Number
    },
    data() {
      return {};
    },
    computed: {
      statusName() {
        let name = "";
        const statusValue = Number(this.status);
        switch (statusValue) {
          case 1:
          case 7:
            name = "正常";
            break;
          case 2:
            name = "迟到";
            break;
          case 3:
            name = "早退";
            break;
          case 4:
            name = "脱岗";
            break;
          case 5:
            name = "未打卡";
            break;
          case 11:
            name = "异常";
            break;
          case 12:
            name = "旷工";
            break;
          case 14:
            name = "请假";
            break;
          case 15:
            name = "外出";
            break;
          case 16:
            name = "出差";
            break;
          case 17:
            name = "缺勤";
            break;
          case 18:
            name = "加班";
            break;
          default:
            break;
        }
        return name;
      },
      statusIconName() {
        let name = "";
        const statusValue = Number(this.status);
        switch (statusValue) {
          case 1:
          case 7:
            name = "kaoqin-zhengchang";
            break;
          case 2:
            name = "kaoqin-chidao";
            break;
          case 3:
            name = "kaoqin-zaotui";
            break;
          case 4:
            name = "kaoqin-tuogang";
            break;
          case 5:
            name = "kaoqin-weidaka";
            break;
          case 11:
            name = "kaoqin-yichang";
            break;
          case 12:
            name = "kaoqin-kuanggong";
            break;
          default:
            break;
        }
        return name;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .en-att-status-contain {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .status-name {
      padding-left: 5px;
      font-size: 12px;
    }
    .en-att-status-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 28px;
      width: 28px;
      background-color: rgb(93, 202, 129);
      border-radius: 14px;
      &.zc {
        background-color: rgb(93, 202, 129);
      }
      &.zt {
        background-color: rgb(37, 159, 216);
      }
      &.cd {
        background-color: rgb(235, 103, 105);
      }
      &.tg {
        background-color: rgb(246, 190, 60);
      }
      &.yc {
        background-color: rgb(235, 103, 105);
      }
      &.kg {
        background-color: rgb(235, 103, 105);
      }
    }
  }
</style>
