<template>
  <el-dialog class="my-dialog" :append-to-body="true" :show-close="false" :close-on-click-modal="false" :visible.sync="dialogDetailVisible">
    <div class="body-contain" :style="isCanEdit ? 'height:440px;' : 'height: 394px;'">
      <span class="title">考勤说明</span>
      <div @click="closeClickAction">
        <en-icon
          name="guanbi-danchuang"
          class="close-icon"
          size="14px"
          :style="{ fill: '#A9B5C6' }"
        ></en-icon>
      </div>
      <div class="content-body-contain">
        <div class="att-detail-contain">
          <en-icon
            name="zuiwandaodian-wodehangcheng"
            size="14"
            class="detail-icon"
          ></en-icon>
          <div class="detail-label">
            {{ dataInfo.recordTime }}
          </div>
          <en-icon
            name="chufachengshi-budan"
            size="14"
            class="detail-icon"
          ></en-icon>
          <div class="detail-label">
            {{ dataInfo.ipAddress }}
          </div>
          <attStatusIcon class="status-icon" :status="dataInfo.status"></attStatusIcon>
        </div>
        <el-form
          ref="dataInfo"
          :mode="dataInfo"
          label-position="left"
          :disabled="!isCanEdit"
        >
          <el-form-item
            v-for="dataItem in dataInfo.projectList"
            :key="dataItem.id"
            :prop="dataItem.id"
            :label="dataItem.name"
          >
            <en-select
              :mode="dataItem.hasChildren ? 'tree' : 'normal'"
              v-model="selectData[dataItem.objectTypeId]"
              :data="dataItem.optionList"
              :props="{ value: 'id', label: 'name', children: 'nodes' }"
              @change="selectChange($event, dataItem)"
              data-mode="data"
              :loading="loading"
              @visible-change="remoteMethod($event, dataItem)"
            ></en-select>
          </el-form-item>

          <el-form-item prop="remark" label="备注">
            <el-input
              v-model="dataInfo.remark"
              type="textarea"
              maxlength="500"
              :autosize="{ minRows: 2, maxRows: 4 }"
              :readonly="!isCanEdit"
              placeholder="请输入考勤备注内容"
            >
            </el-input>
          </el-form-item>
        </el-form>

        <div class="att-image-title-contain">
          <div class="form-att atta-file-wrap" v-if="dataInfo.picList.length">
            <att-image v-for="(item,_index) in dataInfo.picList"
                       :key="item.code"
                       :imgdata="item"
                       :deleteabled="isCanEdit"
                       @delImg="()=>{deleteRow(_index, dataInfo.picList)}">
            </att-image>
          </div>
          <div class="form-att atta-file-wrap" v-else-if="signInfoData.attachment.length">
            <att-image v-for="(item,_index) in signInfoData.attachment"
                       :key="item.code" :imgdata="item"
                       :deleteabled="isCanEdit"
                       @delImg="()=>{deleteRow(_index, signInfoData.attachment)}">
            </att-image>
          </div>
          <!--&& signInfoData.attachment.length < 9-->
          <en-upload v-if="isCanEdit && dataInfo.picList.length < 9"
                     :title="signStrategyData.isMustCamera ? '请使用手机端拍照上传图片' : '添加图片'"
                     :class="signStrategyData.isMustCamera ? 'must-camera' : 'no-must-camera' "
                     :on-change="handleUploadChange"
                     limitNum="9"
                     limitType="3">
          </en-upload>
        </div>
      </div>
      <en-button v-if="isCanEdit" class="comfirm-button" @click="confirmClick">确定</en-button>
    </div>
  </el-dialog>
</template>

<script>
import dayjs from "dayjs";
import signInData from "../signInData";
import attImage from "./attImage";
import attStatusIcon from "./attStatusIcon";

export default {
  name: "EnAttDescriptionDialog",
  props: {
    dialogVisible: {
      type: Boolean,
      default: true
    },
    signStrategyData: {
      type: Object,
      default: () => ({})
    },
    signInfoData: {
      type: Object,
      default: () => ({
        isDisplayRecord: false,
        recordTime: "",
        ipAddress: "",
        confirmFlag: "1",
        isModify: "1",
        isRemark: "1",
        isPicture: "1",
        isMustCamera: "1",
        remark: "",
        attachment: {},
        relations: {},
        status: -1
      })
    }
  },
  components: {
    attImage,
    attStatusIcon
  },
  data() {
    return {
      dialogDetailVisible: true,
      isCanEdit: false,
      loading: false,
      selectData: {},
      dataInfo: {
        id: "",
        recordTime: "",
        ipAddress: "",
        projectList: [
          {
            value: "",
            name: "",
            selectValue: {},
            objectTypeId: "",
            objectDataType: "",
            ids: "",
            optionList: []
          }
        ],
        remark: "",
        picList: [],
        status: -1
      }
    };
  },
  watch: {
    dialogDetailVisible(val) {
      this.$emit("update:dialogVisible", val);
    },
    signInfoData() {
      this.updateDatas();
    }
  },
  mixins: [signInData],
  mounted() {
    this.updateDatas();
  },
  methods: {
    // 关闭
    closeClickAction() {
      this.dialogDetailVisible = false;
      this.$emit("closeAction");
    },
    closeClick() {
      this.dialogDetailVisible = false;
    },
    handleUploadChange({ file }) {
      if (this.signStrategyData.isMustCamera) {
        // this.$message.error("请使用手机端拍照上传图片");
        return;
      }
      if (file.progress === 100) {
        if (file.fileData.type.indexOf("image/") > -1) {
          this.dataInfo.picList.length < 9 ? this.dataInfo.picList.push(file) : this.$message.error("最多只能上传9张图片");
        } else {
          this.$message.error("附件只支持上传图片");
        }
      }
    },
    // 删除元素
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    // 处理打卡显示数据
    updateDatas() {
      if (!this.signInfoData.isDisplayRecord || this.signInfoData.isModify === "0" || this.signInfoData.isModify === 0) {
        this.isCanEdit = true;
      } else {
        this.isCanEdit = false;
      }
      this.dataInfo.recordTime = dayjs(this.signInfoData.recordTime).format("HH:mm");
      if (this.signInfoData.recordType === 1) {
        this.dataInfo.ipAddress = this.signInfoData.addrName;
      } else if (this.signInfoData.recordType === 2) {
        this.dataInfo.ipAddress = this.signInfoData.wifiName;
      } else {
        this.dataInfo.ipAddress = this.signInfoData.ipAddress || this.signInfoData.addrName || this.signInfoData.wifiName;
      }
      this.dataInfo.remark = this.signInfoData.remark;
      this.dataInfo.id = this.signInfoData?.id;
      this.dataInfo.attachment = [...this.signInfoData.attachment];
      this.dataInfo.picList = [...this.signInfoData.attachment];
      this.dataInfo.status = this.signInfoData.status;
      const list = [];

      const relations = [];
      if (this.signInfoData.isDisplayRecord) {
        relations.push(...this.signInfoData.relations);
      } else {
        relations.push(...this.signStrategyData.relations);
      }
      for (let i = 0; i < relations.length; i++) {
        const relation = relations[i];
        const project = {};
        project.name = relation.objectTypeName;
        project.value = relation.id;
        project.objectTypeId = relation.objectTypeId;
        project.objectDataType = relation.objectDataType;
        project.ids = relation.value;
        project.optionList = [];
        if (relation.dataName && relation.dataId) {
          this.selectData[relation.objectTypeId] = relation.dataName;
          project.selectValue = {
            id: relation.dataId,
            name: relation.dataName
          };
        } else {
          this.selectData[relation.objectTypeId] = "";
        }
        list.push(project);
      }
      this.dataInfo.projectList = [...list];
    },
    changeList(val) {
      this.dataInfo.picList = [...val];
      console.log(val);
    },
    // 确定
    confirmClick() {
      const isModify = this.signInfoData.isModify === "0" || this.signInfoData.isModify === 0;
      const isRemark = (isModify && (this.signInfoData.isRemark === 0 || this.signInfoData.isRemark === "0"));
      if (this.signStrategyData.isRemark || isRemark) {
        if (this.dataInfo.remark === null || this.dataInfo.remark?.length === 0) {
          this.$message.error("请填写考勤备注");
          return;
        }
      }
      const isPicture = (isModify && (this.signInfoData.isPicture === 0 || this.signInfoData.isPicture === "0"));
      if (this.signStrategyData.isPicture || isPicture) {
        if (this.dataInfo.picList === null || this.dataInfo.picList.length === 0) {
          this.$message.error("请上传考勤图片");
          return;
        }
      }
      if (!this.signInfoData.isDisplayRecord) {
        const tmpList = [];
        if (this.signStrategyData.relations?.length > 0) {
          for (let i = 0; i < this.signStrategyData.relations.length; i++) {
            const obj0 = this.signStrategyData.relations[i];
            const obj = this.dataInfo.projectList[i];
            if (obj0?.isRequired === "0" || obj0?.isRequired === 0) {
              if (obj.selectValue === null || obj.selectValue === undefined) {
                const msg = `请选择关联${ obj.name}`;
                this.$message.error(msg);
                return;
              }
            }
            if (obj.selectValue != null) {
              tmpList.push(obj);
            }
          }
        }
        this.dataInfo.projectList = [...tmpList];
      }
      this.$emit("confirmWithInputValue", this.dataInfo);
      this.closeClick();
    },
    // 删除图片
    deleteImage() {
      this.dataInfo.picList.splice(0, 1);
    },
    // 获取配置项数据
    async remoteMethod(visible, dataItem) {
      if (dataItem !== null && visible) {
        this.loading = true;
        if (dataItem?.optionList?.length > 0) {
          this.loading = false;
          return;
        }
        const params = {
          objectTypeId: dataItem?.objectTypeId,
          ids: dataItem?.ids
        };
        const rsp = await this.requestObjectTreeData(params);

        if (Array.isArray(rsp)) {
          rsp?.forEach((item) => {
            item.value = item.name;
            item.label = item.name;
          });
        }
        dataItem.optionList = [...rsp];
        if (dataItem.selectValue === undefined) {
          dataItem.selectValue = {};
        }
        let hasChildren = false;
        for (let i = 0; i < dataItem?.optionList?.length; i++) {
          const tmpItem = dataItem.optionList[i];
          if (tmpItem.hasChildren === "000") {
            hasChildren = true;
            break;
          }
        }
        dataItem.hasChildren = hasChildren;
        this.loading = false;
      }
    },
    changeProjectData(dataItem, item) {
      dataItem.selectValue = item;
      for (let i = 0; i < this.dataInfo.projectList.length; i++) {
        const tmp = this.dataInfo.projectList[i];
        if (dataItem.objectTypeId === tmp.objectTypeId) {
          tmp.selectValue = {
            id: item.id,
            name: item.name
          };
        }
      }
    },
    selectChange(item, dataItem) {
      this.selectData[dataItem.objectTypeId] = item.name;
      dataItem.selectValue = item;
      for (let i = 0; i < this.dataInfo.projectList.length; i++) {
        const tmp = this.dataInfo.projectList[i];
        if (dataItem.objectTypeId === tmp.objectTypeId) {
          tmp.selectValue = {
            id: item.id,
            name: item.name
          };
        }
      }
      this.$forceUpdate();
    }
  }
};
</script>

<style lang="scss" scoped>
  .body-contain {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;

    width: 705px;
    height: 440px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border-radius: 4px;

    .title {
      position: absolute;
      left: 20px;
      top: 20px;
      height: 14px;
      font-size: 14px;
      line-height: 14px;
      font-family: MicrosoftYaHei;
      font-weight: bold;
      text-align: left;
      color: rgba(51, 51, 51, 1);
    }
    .close-icon {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 14px;
      height: 14px;
    }
    .comfirm-button {
      position: absolute;
      right: 20px;
      bottom: 16px;
      width: 72px;
      height: 32px;
    }

    .content-body-contain {
      //border: 1px solid #dce5ec;
      margin: 52px 18px 55px 20px;
      max-height: 335px;
      height: auto;
      /*overflow-y: auto;*/
      /*overflow-x: hidden;*/
      & /deep/ .el-form-item__content {
        text-align: left;
      }
      .must-camera {
        & /deep/ .en-button {
          span {
            color: #91A1B7;
            font-size: 12px !important;
            font-family: MicrosoftYaHei !important;
            font-weight: normal !important;
          }
        }
      }
      .no-must-camera {
        & /deep/ .en-button {
          span {
            color: #636C78;
            font-size: 12px !important;
            font-family: MicrosoftYaHei !important;
            font-weight: normal !important;
          }
        }
      }
      .att-detail-contain {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        .detail-icon {
          margin-right: 5px;
        }
        .detail-label {
          margin-right: 15px;
        }
        .status-icon {
          display: none;
          position: absolute;
          right: 0px;
        }
      }

      & /deep/ .el-form {
        .el-form-item__label {
          width: 60px !important;
        }
        .el-select {
          width: calc(100% - 60px);
        }
        .el-input__inner {
          padding-left: 12px;
        }
        .el-textarea {
          width: calc(100% - 60px);
        }
        .el-textarea__inner {
          padding-left: 12px;
          font-size: 12px;
          resize: none;
        }
      }
      .att-image-title-contain {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        line-height: 16px;
        & /deep/ .el-upload {
          display: flex;
          justify-content: flex-start;
          line-height: 16px;
          .en-button {
            color: #333333 !important;
          }
          .en-icon-svg {
            color: #A9B5C5 !important;
          }
        }

        .en-icon {
          color: #a9b5c6;
          margin-right: 6px;
        }

        .label {
          color: #333333;
          font-size: 12px;
        }

        .form-att {
          //padding: 10px 0 5px 0px;
          padding: 0;
          /*background-color: #f5f7fa;*/
          display: flex;
          flex-direction: column;
        }
        .atta-file-wrap{
          flex-direction: row;
          flex-wrap: wrap;
        }
      }
      .att-image-contain {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .image-contain {
          position: relative;
          width: 64px;
          height: 64px;
          margin-right: 12px;
          margin-bottom: 12px;
          border: 1px solid #dce5ec;
          .delet-contain {
            position: absolute;
            top: -5px;
            right: -5px;
            width: 20px;
            height: 20px;
            text-align: right;
            display: none;
          }
          &:hover {
            .delet-contain {
              display: block;
            }
          }
        }
      }
    }
  }

  .my-dialog {
    & /deep/ .el-dialog__header {
      margin: 0;
      height: 0;
      padding: 0;
    }
    & /deep/ .el-dialog__body {
      padding: 0;
      margin: 0;
    }
  }

</style>
