<template>
  <div>
    <span class="img-wrap">
      <en-image
        :src="cleImg.fileUrl|| cleImg.url||cleImg.code||cleImg.filePath"
        fit="cover"
        class="img wh"
        :title="cleImg.name"
        lazy
      ></en-image>
      <span
        class="del"
        v-if="deleteabled"
        @click="delFile"
      >
        <en-icon
          name="shanchu-liebiao"
          size="small"
        >
        </en-icon>
      </span>
    </span>
  </div>
</template>
<script>
  export default {
    name: "attImage",
    props: {
      // 图片数据
      imgdata: {
        type: Object,
        required: true
      },
      deleteabled: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        cleImg: JSON.parse(JSON.stringify(this.imgdata))
      };
    },
    methods: {
      // 删除一个图片方法
      delFile() {
        // const a = JSON.parse(JSON.stringify(this.cleImg));
        // console.log(a);
        // debugger;
        this.$emit("delImg", this.cleImg.id);
      }
    }
  };
</script>
<style lang='scss' scoped>
  .img-wrap {
    display: block;
    float: left;
    width: 69px;
    height: 65px;
    border-radius: 4px;
    margin-right: 5px;
    margin-bottom: 5px;
    position: relative;
    &:hover {
      .del {
        display: block;
      }
    }
    .wh {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      border: 1px solid transparent;
    }
    .del {
      color: #f54646;
      position: absolute;
      top: -7px;
      right: -7px;
      display: none;
      z-index: 2;
      cursor: pointer;
    }
  }
</style>
